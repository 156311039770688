<template>
  <footer class="w-full py-4 bg-gray-100 mt-auto">
    <div class="container mx-auto text-center text-gray-600">
      @ 2022-2025 | Inspectman.com
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Footer'
})
</script>