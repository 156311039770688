<template>
  <div class="min-h-screen flex flex-col">
    <template v-if="!loading">
      <Navigation v-if="showNavigation" />
      <div class="flex-grow">
        <router-view v-slot="{ Component }">
          <transition
            name="fade"
            mode="out-in"
          >
            <keep-alive :include="cachedComponents">
              <component 
                :is="Component"
                :key="$route.fullPath"
              />
            </keep-alive>
          </transition>
        </router-view>
      </div>
      <Footer />
    </template>
    <div v-else class="min-h-screen flex items-center justify-center">
      <div class="text-center">
        <svg class="animate-spin h-8 w-8 text-primary mx-auto mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <p class="text-gray-600 dark:text-gray-400">Loading...</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useRoute } from 'vue-router'
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import { auth, waitForFirestore } from '@/firebase'

const authStore = useAuthStore()
const route = useRoute()
const loading = ref(true)
const initError = ref<Error | null>(null)

// Hide navigation on auth pages
const showNavigation = computed(() => !route.path.startsWith('/auth'))

// List of components to cache
const cachedComponents = [
  'UserDashboard',
  'AdminDashboard',
  'AdminSettings',
  'AdminProductConfig',
  'UserList',
  'InspectorDashboard'
]

// Initialize auth state with better error handling
const initializeAuth = async () => {
  try {
    // Ensure Firestore is ready
    await waitForFirestore()
    
    // Check auth state
    await authStore.checkAuth()
    
    // If we have a user, ensure profile is loaded
    if (authStore.user) {
      const profile = await authStore.fetchUserProfile()
      if (!profile) {
        console.warn('User authenticated but no profile found')
      }
    }

    initError.value = null
  } catch (error) {
    console.error('Failed to initialize application:', error)
    initError.value = error instanceof Error ? error : new Error('Failed to initialize')
  } finally {
    loading.value = false
  }
}

// Initialize on mount and when auth state changes
onMounted(() => {
  initializeAuth()
  
  // Watch for auth state changes
  const unsubscribe = auth.onAuthStateChanged(async (user) => {
    loading.value = true
    try {
      if (user) {
        await initializeAuth()
      } else {
        authStore.userProfile = null
      }
    } catch (error) {
      console.error('Error during auth state change:', error)
      initError.value = error instanceof Error ? error : new Error('Auth state change failed')
    } finally {
      loading.value = false
    }
  })

  // Clean up listener on unmount
  onUnmounted(() => {
    unsubscribe()
  })
})
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
