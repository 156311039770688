import { createRouter, createWebHistory, RouteRecordRaw, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useAuthStore } from '../stores/auth'
import { adminRoutes } from './admin.routes'

// Define async component loader with error handling
const asyncComponent = (loader: () => Promise<any>) => {
  return async () => {
    try {
      return await loader()
    } catch (error) {
      console.error('Error loading component:', error)
      throw error
    }
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/payment/:id',
    name: 'payment',
    component: asyncComponent(() => import('../views/PaymentStep.vue')),
    props: true,
    beforeEnter: (to, _from, next) => {
      const reorderData = localStorage.getItem('reorderBookingData')
      if (reorderData) {
        next()
      } else {
        next({ name: 'booking' })
      }
    }
  },
  {
    path: '/payment/success',
    name: 'payment-success',
    component: asyncComponent(() => import('../views/PaymentSuccess.vue')),
    props: (route) => {
      try {
        const params = route.query || route.params
        const decodedParams = Object.entries(params).reduce((acc, [key, value]) => {
          try {
            acc[key] = typeof value === 'string' ? decodeURIComponent(value) : value
          } catch (e) {
            console.error('Error decoding parameter:', { key, value, error: e })
            acc[key] = value
          }
          return acc
        }, {} as Record<string, any>)

        const paymentProps = {
          payerId: decodedParams.PayerID || decodedParams.payer_id || decodedParams.payerId,
          paymentId: decodedParams.paymentId || decodedParams.payment_id || decodedParams.PaymentID,
          token: decodedParams.token || decodedParams.TOKEN,
          tx: decodedParams.tx || decodedParams.txn_id || decodedParams.transaction_id || decodedParams.TRANSACTIONID,
          orderId: decodedParams.orderId || decodedParams.order_id || decodedParams.orderID || decodedParams.ORDER,
          invoiceId: decodedParams.invoice || decodedParams.custom_id || decodedParams.tracking_id,
          paymentStatus: decodedParams.payment_status || decodedParams.PAYMENTSTATUS,
          correlationId: decodedParams.correlation_id || decodedParams.CORRELATIONID,
          customId: decodedParams.custom_id,
          trackingId: decodedParams.tracking_id,
          originalQuery: decodedParams
        }

        const payerId = decodedParams.PayerID || decodedParams.payer_id
        if (!payerId) {
          console.error('Missing PayerID in payment response')
          throw new Error('Missing payment identifier')
        }

        paymentProps.payerId = payerId
        if (decodedParams.token) {
          paymentProps.token = decodedParams.token
        }

        paymentProps.paymentId = decodedParams.paymentId || decodedParams.payment_id
        paymentProps.tx = decodedParams.tx || decodedParams.transaction_id

        return paymentProps
      } catch (error) {
        console.error('Error processing payment parameters:', error)
        return {
          error: error instanceof Error ? error.message : 'Unknown error processing payment'
        }
      }
    }
  },
  {
    path: '/payment/cancel',
    name: 'payment-cancel',
    component: asyncComponent(() => import('../views/PaymentCancel.vue')),
    props: (route) => {
      const params = route.query || route.params
      const decodedParams = Object.entries(params).reduce((acc, [key, value]) => {
        try {
          acc[key] = typeof value === 'string' ? decodeURIComponent(value) : value
        } catch (e) {
          console.error('Error decoding parameter:', { key, value, error: e })
          acc[key] = value
        }
        return acc
      }, {} as Record<string, any>)
      
      return {
        token: decodedParams.token,
        orderId: decodedParams.orderId,
        payerId: decodedParams.PayerID,
        paymentId: decodedParams.paymentId,
        tx: decodedParams.tx,
        error: decodedParams.error
      }
    }
  },
  {
    path: '/',
    name: 'landing',
    component: asyncComponent(() => import('../views/LandingPage.vue'))
  },
  {
    path: '/booking',
    name: 'booking',
    component: asyncComponent(() => import('../views/BookingEngine.vue')),
    meta: { requiresAuth: true }
  },
  {
    path: '/booking-confirmation',
    name: 'booking-confirmation',
    component: asyncComponent(() => import('../views/BookingConfirmation.vue')),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: asyncComponent(() => import('../views/UserDashboard.vue')),
    meta: { requiresAuth: true, requiresVerified: true },
    beforeEnter: async (_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
      const authStore = useAuthStore()
      if (!authStore.initialized) {
        await authStore.checkAuth()
      }
      if (!authStore.isAuthenticated) {
        next({ name: 'login' })
      } else if (authStore.isAdmin) {
        next({ name: 'admin-dashboard' })
      } else if (authStore.isInspector) {
        next({ name: 'inspector-dashboard' })
      } else {
        next()
      }
    }
  },
  {
    path: '/inspector',
    name: 'inspector-dashboard',
    component: asyncComponent(() => import('../views/InspectorDashboard.vue')),
    meta: { requiresAuth: true, requiresInspector: true, requiresVerified: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: asyncComponent(() => import('../views/UserProfile.vue')),
    meta: { requiresAuth: true, requiresVerified: true }
  },
  {
    path: '/inspections',
    name: 'inspections',
    component: asyncComponent(() => import('../views/Inspections.vue')),
    meta: { requiresAuth: true, requiresVerified: true }
  },
  {
    path: '/bookings',
    name: 'bookings',
    component: asyncComponent(() => import('../views/Bookings.vue')),
    meta: { requiresAuth: true, requiresVerified: true }
  },
  {
    path: '/bookings/:id',
    name: 'booking-details',
    component: asyncComponent(() => import('../views/BookingDetails.vue')),
    meta: { requiresAuth: true, requiresVerified: true }
  },
  {
    path: '/reports',
    name: 'reports',
    component: asyncComponent(() => import('../views/Reports.vue')),
    meta: { requiresAuth: true, requiresVerified: true }
  },
  {
    path: '/inspections/:id',
    name: 'inspection-details',
    component: asyncComponent(() => import('../views/InspectionDetails.vue')),
    meta: { requiresAuth: true, requiresVerified: true }
  },
  {
    path: '/auth',
    children: [
      {
        path: 'login',
        name: 'login',
        component: asyncComponent(() => import('../views/auth/Login.vue'))
      },
      {
        path: 'register',
        name: 'register',
        component: asyncComponent(() => import('../views/auth/Register.vue'))
      },
      {
        path: 'verify-email',
        name: 'verify-email',
        component: asyncComponent(() => import('../views/auth/VerifyEmail.vue')),
        meta: { requiresAuth: true }
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: asyncComponent(() => import('../views/auth/ForgotPassword.vue'))
      }
    ]
  },
  {
    path: '/reports/:id',
    name: 'report-management',
    component: asyncComponent(() => import('../views/ReportManagement.vue')),
    meta: { requiresAuth: true, requiresVerified: true }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: to => {
      // Don't redirect valid booking detail routes
      if (to.fullPath.match(/^\/bookings\/[^/]+$/)) {
        return to.fullPath;
      }
      console.error('Route not found:', to.fullPath)
      return { name: 'dashboard' }
    }
  }
]

// Add admin routes
routes.push(...adminRoutes)

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Global navigation guard
router.beforeEach(async (_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
  // Allow payment routes to proceed without auth check
  if (_to.path.startsWith('/payment/')) {
    next()
    return
  }

  const authStore = useAuthStore()
  
  // Always ensure auth is initialized first
  if (!authStore.initialized) {
    try {
      await authStore.checkAuth()
    } catch (error) {
      console.error('Failed to initialize auth:', error)
      if (_to.name !== 'login') {
        next({ name: 'login' })
        return
      }
    }
  }

  const requiresAuth = _to.matched.some((record) => record.meta.requiresAuth)
  const requiresAdmin = _to.matched.some((record) => record.meta.requiresAdmin)
  const requiresInspector = _to.matched.some((record) => record.meta.requiresInspector)
  const requiresVerified = _to.matched.some((record) => record.meta.requiresVerified)

  // Allow access to non-protected routes
  if (!requiresAuth) {
    next()
    return
  }

  // Redirect to login if not authenticated
  if (!authStore.isAuthenticated) {
    next({ 
      name: 'login', 
      query: { redirect: _to.fullPath } 
    })
    return
  }

  // Check email verification
  if (requiresVerified && !authStore.isEmailVerified) {
    next({ 
      name: 'verify-email',
      query: { email: authStore.user?.email }
    })
    return
  }

  // Check admin access
  if (requiresAdmin && !authStore.isAdmin) {
    next({ name: 'dashboard' })
    return
  }

  // Check inspector access
  if (requiresInspector && !authStore.isInspector) {
    next({ name: 'dashboard' })
    return
  }

  next()
})

export default router