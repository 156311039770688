import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { VueFire, VueFireAuth } from 'vuefire'
import { app as firebaseApp } from './firebase'
import App from './App.vue'
import router from './router'

// Import base styles
import './assets/main.css'

// Create Vue app
const app = createApp(App)

// Initialize Pinia store
const pinia = createPinia()
app.use(pinia)

// Initialize VueFire
app.use(VueFire, {
  firebaseApp,
  modules: [
    VueFireAuth(),
  ],
})

// Initialize Router
app.use(router)

// Mount app
app.mount('#app')
