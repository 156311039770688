import { RouteRecordRaw } from 'vue-router'

// Explicitly import admin components
const AdminDashboard = () => import('../views/AdminDashboard.vue')
const AdminSettings = () => import('../views/AdminSettings.vue')
const AdminProductConfig = () => import('../views/AdminProductConfig.vue')
const UserList = () => import('../views/UserList.vue')

export const adminRoutes: Array<RouteRecordRaw> = [
  {
    path: '/admin',
    name: 'admin-dashboard',
    component: AdminDashboard,
    meta: { requiresAuth: true, requiresAdmin: true, requiresVerified: true }
  },
  {
    path: '/admin/products',
    name: 'admin-products',
    component: AdminProductConfig,
    meta: { requiresAuth: true, requiresAdmin: true, requiresVerified: true }
  },
  {
    path: '/admin/settings',
    name: 'admin-settings',
    component: AdminSettings,
    meta: { requiresAuth: true, requiresAdmin: true, requiresVerified: true }
  },
  {
    path: '/admin/users',
    name: 'user-list',
    component: UserList,
    meta: { requiresAuth: true, requiresAdmin: true, requiresVerified: true }
  }
]